import { forwardRef, useMemo, useState } from "react";
import { DefaultProps, Flex, SelectItem } from "@mantine/core";
import classNames from "classnames";
import { IconDropdownDown } from "public/icons/IconDropdownDown";
import { IconMarkCheckS } from "public/icons/IconMarkCheckS";
import Loader from "shared/ui/Loader";
import { useStyles } from "shared/ui/Select/StyledSelect/styles";
import Typography from "shared/ui/Typography/Typography";
import { BaseSelect, BaseSelectProps } from "../BaseSelect";

export interface StyledSelectProps extends BaseSelectProps {
    className?: string;
    isLoading?: boolean;
    variant?: "default" | "bordered";
}
const SelectItemComponent = forwardRef<HTMLDivElement, SelectItem>((item: SelectItem, ref) => (
    <div ref={ref} {...item}>
        <Flex gap={6}>
            <Typography tag="mMedium" color="inherit">
                {item.label ?? item.value}
            </Typography>
            {item.selected && <IconMarkCheckS />}
        </Flex>
    </div>
));
SelectItemComponent.displayName = "SelectItem";

export const StyledSelect = ({ styles = {}, ...props }: StyledSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { classes } = useStyles();

    const rightSection = useMemo(() => {
        if (props.rightSection) {
            return props.rightSection;
        }
        if (props.isLoading) {
            return <Loader size={16} />;
        }
        return <IconDropdownDown />;
    }, [props.rightSection, props.isLoading]);

    const getStyles: DefaultProps["styles"] = (theme, params) => {
        const externalStyles = typeof styles === "function" ? styles(theme, params) : styles;

        const result = {
            root:
                props.variant === "bordered"
                    ? {
                          padding: 8,
                          border: "1px solid #b0b0b0",
                      }
                    : undefined,
            rightSection: {
                display: props.options?.length === 0 && !props.isLoading ? "none" : "flex",
                transform: isOpen ? "rotate(180deg)" : "rotate(0)",
            },
            ...externalStyles,
        };

        return result;
    };

    return (
        <BaseSelect
            {...props}
            onDropdownOpen={() => {
                setIsOpen(true);
                props.onDropdownOpen?.();
            }}
            onDropdownClose={() => {
                setIsOpen(false);
                props.onDropdownClose?.();
            }}
            itemComponent={SelectItemComponent}
            styles={getStyles}
            className={classNames(props.className)}
            classNames={classes}
            rightSection={rightSection}
        />
    );
};
